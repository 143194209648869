<template>
  <div class="home">
    <interview></interview>
  </div>
</template>

<script>
// @ is an alias to /src
import interview from './interview/index'

export default {
  name: 'Home',
    components:{interview},
}
</script>
